import { useStaticQuery, graphql } from "gatsby"

export const useInsuranceQuery = () => {


    const data = useStaticQuery(graphql`
    query InsuranceQuery {
        wpPage(title: {eq: "INSURANCE"}) {
          ACF_InsurancePage {

            hero {
                image {
                    publicUrl
                }
                shortTitle
                content
            }

            contentinsurance {
                content
            }

            insuranceMetaData {
                metaTitle
                metaDescriptioin
            }
        
        }
        }
    }     
    `)
    return data.wpPage.ACF_InsurancePage;
}