import * as React from "react";
import Layout from "../components/global/Layout";
import Hero from "../components/insurance/Hero";
import Content from "../components/insurance/Content";
import { useInsuranceQuery } from "../hooks/useInsuranceQuery";
import Helmet from "react-helmet";


const InsurancePage = () => {
    const data = useInsuranceQuery();
    return (
      <Layout>
        <Hero data={data.hero} />
        <Content data={data.contentinsurance} />
        <Helmet>
          <title>{data.insuranceMetaData.metaTitle}</title>
          <meta name="description" content={data.insuranceMetaData.metaDescriptioin} />
        </Helmet>
      </Layout>
    )
  };
  
  export default InsurancePage;